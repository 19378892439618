<template>
  <div>
    <el-card class="searchContainer">
      <h2>说明：</h2>
      <p>1.可自定设定等级名称和升级条件</p>
      <p>2.所有刚加入分销的分销员，默认为C级合伙人，达到升级条件自动升级</p>
      <p>3.所有升级采取只升不降原则，期间修改升级条件，则C级合伙人以上等级不变</p>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>分销级别</span>
        <el-button @click="edit('')" type="success" style="float: right">添加</el-button>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="levelName" label="分销等级"> </el-table-column>
        <el-table-column align="center" prop="withdrawLowerLimit" label="佣金提现下限"> </el-table-column>
        <el-table-column align="center" prop="withdrawUpperLimit" label="佣金提现上限"> </el-table-column>
        <el-table-column align="center" prop="teamNum" label="团队人数"> </el-table-column>
        <el-table-column align="center" prop="directPushProportion" label="分销佣金比例"> </el-table-column>
        <el-table-column align="center" prop="indirectPushProportion" label="上线分销佣金比例"> </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small"> 编辑</el-button>
            <el-button @click="showDelete(scope)" class="deleteBtnText" type="text" size="small"> 删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>分销设置</span>
      </div>

      <el-form ref="form" class="distributionSetting" :rules="Rules" :model="form" label-width="200px">
        <el-form-item label="分销员与普通用户关系" prop="distributionType">
          <el-radio-group v-model="form.distributionType">
            <el-radio :label="0">分享不绑定</el-radio>
            <el-radio :label="1">分享弱绑定</el-radio>
            <!-- <el-radio :label="2">分享强绑定</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="平台全收部分奖励分销比" prop="advertisingEarnings">
          <el-input v-model="form.advertisingEarnings" oninput="value=value.replace(/[^0-9.]/g,'')"
            ><template slot="append">%</template></el-input
          >
        </el-form-item>
        <el-form-item label="分销奖励金上限" prop="distributionBounty">
          <el-input v-model="form.distributionBounty" oninput="value=value.replace(/[^0-9.]/g,'')"
            ><template slot="append">元</template></el-input
          >
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveSetting()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-dialog :title="title" :visible.sync="ruleDialog" width="30%" :close-on-click-modal="false">
      <el-form class="ruleForm" ref="ruleForm" :rules="DialogRules" :model="ruleForm" label-width="170px">
        <el-form-item label="分销等级" prop="levelName">
          <el-input placeholder="请输入分销等级名称" v-model.trim="ruleForm.levelName"> </el-input>
        </el-form-item>

        <el-form-item label="可否提现直接售卖佣金" prop="activityName">
          <el-switch
            v-model="ruleForm.isDirectPushWithdrawable"
            :inactive-value="false"
            :active-value="true"
          ></el-switch>
        </el-form-item>
        <el-form-item label="可否提现团队佣金" prop="activityName">
          <el-switch
            v-model="ruleForm.isIndirectPushWithdrawable"
            :inactive-value="false"
            :active-value="true"
          ></el-switch>
        </el-form-item>
        <el-form-item label="是否显示分销金" prop="distributionAmountDisplayFlag">
          <el-switch
            v-model="ruleForm.distributionAmountDisplayFlag"
            :inactive-value="false"
            :active-value="true"
          ></el-switch>
        </el-form-item>
        <el-form-item label="团队人数" prop="teamNum">
          <el-input placeholder="请输入团队人数" oninput="value=value.replace(/[^0-9]/g,'')" v-model="ruleForm.teamNum">
            <template slot="append">人</template>
          </el-input>
        </el-form-item>
        <el-form-item label="分销佣金比例" prop="directPushProportion">
          <el-input
            placeholder="请输入分销佣金比例"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            v-model="ruleForm.directPushProportion"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="上线分销佣金比例" prop="indirectPushProportion">
          <el-input
            placeholder="请输入上线分销佣金比例"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            v-model="ruleForm.indirectPushProportion"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>

        <el-form-item label="每日提现次数" prop="limitNum">
          <el-input
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入每日提现次数"
            v-model="ruleForm.limitNum"
          >
            <template slot="append">次</template>
          </el-input>
        </el-form-item>
        <el-form-item label="单次提现上限" prop="withdrawUpperLimit">
          <el-input
            placeholder="请输入单次提现上限"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            v-model="ruleForm.withdrawUpperLimit"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="单次提现下限" prop="withdrawLowerLimit">
          <el-input
            oninput="value=value.replace(/[^0-9.]/g,'')"
            placeholder="请输入单次提现下限"
            v-model="ruleForm.withdrawLowerLimit"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ruleDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmRule">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除组件 -->
    <bm-deleteItem
      url="boom-center-admin-service/sysAdmin/distribution/rule/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'
export default {
  mixins: [pagination, deleteRowItem],
  data() {
    return {
      form: { advertisingEarnings: '', distributionBounty: '', distributionType: 1 },
      DialogRules: [],
      Rules: [],
      ruleForm: {
        directPushProportion: 0,
        // 直推比例
        indirectPushProportion: 0,
        // 间接比例
        isDirectPushWithdrawable: true,
        // 是否允许提现直推佣金
        isIndirectPushWithdrawable: true,
        distributionAmountDisplayFlag: false, //是否显示分销金
        levelName: '',
        // 等级名称
        teamNum: 0,
        // 团队人数要求
        withdrawLowerLimit: 0,
        // 佣金提现下限
        withdrawUpperLimit: 0
        // 佣金提现上限
      },
      blockContext: '',
      title: '新增规则',
      ruleDialog: false,
      list: [],
      isEdit: false,
      timestamp: 0
    }
  },
  created() {
    this.getRules()
    this.getList()
    this.getSetting()
  },
  methods: {
    getSetting() {
      this.$api.distribution
        .distribution('getDistributionSetting')
        .then(res => {
          this.form = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    saveSetting() {
      delete this.form.tenantId
      this.$api.distribution
        .distribution('putDistributionSetting', {
          ...this.form
        })
        .then(res => {
          this.$message.success('修改成功')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },

    getRules() {
      this.Rules = this.$initRules([
        {
          label: '广告收益奖励金',
          value: 'advertisingEarnings',
          type: 'input',
          required: true
        },
        {
          label: '分销奖励金上限',
          value: 'distributionBounty',
          type: 'input',
          required: true
        },
        {
          label: '分销员与普通用户关系',
          value: 'distributionType',
          type: 'input',
          required: true
        }
      ])
      this.DialogRules = this.$initRules([
        {
          label: '分销等级',
          value: 'levelName',
          type: 'input',
          required: true
        },
        {
          label: '团队人数',
          value: 'teamNum',
          type: 'input',
          required: true
        },
        {
          label: '分销佣金比例',
          value: 'directPushProportion',
          type: 'input',
          required: true
        },
        {
          label: '上线分销佣金比例',
          value: 'indirectPushProportion',
          type: 'input',
          required: true
        },
        {
          label: '每日提现次数',
          value: 'limitNum',
          type: 'input',
          required: true
        },
        {
          label: '单次提现上限',
          value: 'withdrawUpperLimit',
          type: 'input',
          required: true
        },
        {
          label: '单次提现下限',
          value: 'withdrawLowerLimit',
          type: 'input',
          required: true
        }
      ])
    },
    // 请求后端获取规则列表列表
    getList() {
      this.$api.distribution
        .distribution('distributionRuleList')
        .then(res => {
          this.list = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    edit(row) {
      if (row) {
        this.ruleDialog = true
        this.isEdit = true
        this.ruleForm = { ...row }
        this.title = '编辑规则'
      } else {
        this.title = '新增规则'
        this.isEdit = false
        this.ruleDialog = true
        this.ruleForm = {
          directPushProportion: 0,
          indirectPushProportion: 0,
          isDirectPushWithdrawable: true,
          isIndirectPushWithdrawable: true,
          levelName: '',
          teamNum: 0,
          withdrawLowerLimit: 0,
          withdrawUpperLimit: 0
        }
      }
    },
    // 确认添加或者修改规则
    confirmRule() {
      let { tenantId, updateTime, updateUserId, deleteStatus, createTime, ...params } = this.ruleForm

      this.$refs['ruleForm'].validate((valid, validObj) => {
        if (valid) {
          if (parseInt(this.ruleForm.directPushProportion) + parseInt(this.ruleForm.indirectPushProportion) > 100) {
            this.$message.error('分销佣金比例+上线分销佣金比例 不能大于100%')
            return
          }

          if (parseInt(this.ruleForm.withdrawUpperLimit) < parseInt(this.ruleForm.withdrawLowerLimit)) {
            this.$message.error('单次提现上限要大于等于下限')
            return
          }

          if (this.isEdit) {
            this.$api.distribution
              .distribution('putDistributionRule', params, this.ruleForm.id)
              .then(res => {
                this.getList()
                this.ruleDialog = false
                this.$message.success('添加成功')
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
          } else {
            this.$api.distribution
              .distribution('postDistributionRule', params)
              .then(res => {
                this.getList()
                this.ruleDialog = false
                this.$message.success('添加成功')
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
          }
        } else {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return true
        }
      })

      this.$api.distribution
        .distribution('userPullBlack')
        .then(res => {})
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.ruleForm {
  .el-input {
    width: 200px;
  }
}
.distributionSetting {
  .el-input {
    width: 150px;
  }
}
</style>
